import React from "react";
import styled from "styled-components";
// import InfoBox from "./InfoBox";
import InfoBox from "./InfoBox";

interface BoxContainerProps {
  children: React.ReactNode;
}

const BoxContainer: React.FC<BoxContainerProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default BoxContainer;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0rem;
`;
