import React, { useState } from "react";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa"; // Importing an icon from react-icons
import colors from "../assets/colors/colors";
import { ComponentWidth } from "../assets/style/width";
import { useRecoilState, useRecoilValue } from "recoil";
import { optionState } from "../state/option";
import { searchState } from "../state/search";
import { dataState } from "../state/data";
import { useNavigate } from "react-router-dom";

interface SearchBarProps {
  // Add props here
  openModal: (player: string) => void;
  searchManagerId: (managerId: number) => void;
  searchManagerName: (managerName: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ openModal, searchManagerId, searchManagerName }) => {
  const [option, setOption] = useRecoilState(optionState);
  const [search, setSearch] = useRecoilState(searchState);

  const [searchTerm, setSearchTerm] = useState(
    option.searchType === "player"
      ? search.playerName
      : option.searchType === "managerId"
      ? search.managerId
      : search.managerName
  );
  let navigate = useNavigate();

  const handleSearch = (event: any) => {
    event.preventDefault();
    if (option.searchType === "player") {
      openModal(searchTerm);
    } else if (option.searchType === "managerId") {
      // Search by manager ID
      const managerId = parseInt(searchTerm);
      if (managerId) {
        setOption({ ...option, managerMenuOption: "search" });
        setSearch({ ...search, managerId: managerId });
        searchManagerId(managerId);
      }
    } else {
      // Search by manager name
      setOption({ ...option, managerMenuOption: "search" });
      setSearch({ ...search, managerName: searchTerm });
      searchManagerName(searchTerm);
    }

    // Redirect to manager page
    navigate(`/manager`);
  };

  const searchPlaceHolder = () => {
    if (option.searchType === "player") {
      return "Enter Player Name";
    } else if (option.searchType === "managerId") {
      return "Enter Manager ID";
    } else {
      return "Enter Manager Name";
    }
  };

  return (
    <SearchContainer>
      <SearchForm onSubmit={handleSearch}>
        <SearchType
          value={option.searchType}
          onChange={(e) =>
            setOption({
              ...option,
              searchType: e.target.value,
            })
          }
        >
          <option value="player">Player</option>
          <option value="managerId">Manager Id/Name</option>
          {/* <option value="manager">ManagerName</option> */}
        </SearchType>
        <SearchInput
          type="text"
          placeholder={searchPlaceHolder()}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <SearchButton type="submit">
          <FaSearch color={colors.baseText} />
        </SearchButton>
      </SearchForm>
    </SearchContainer>
  );
};

export default SearchBar;

// Styled components
const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 5px;
  border: none;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const SearchForm = styled.form`
  display: flex;
  border-radius: 0.5rem;
  background-color: ${colors.inputBackground};
  padding: 0.5rem 1rem;
  height: 1.5rem;
  width: 25rem;
`;

const SearchType = styled.select`
  color: ${colors.baseText};
  border: none;
  border-radius: 2px 0 0 2px; // Rounded corners on the left side only
  outline: none;
  font-size: 0.8rem;
  background-color: ${colors.inputBackground};
`;

const SearchInput = styled.input`
  width: ${ComponentWidth.HomeComponent.media_large.small};
  padding: 0.5rem;
  color: ${colors.baseText};
  border: none;
  border-radius: 2px 0 0 2px; // Rounded corners on the left side only
  outline: none;
  background-color: ${colors.inputBackground};

  &:focus {
    border-color: #4a90e2;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SearchButton = styled.button`
  padding: 8px 12px;
  margin-left: 0.3rem;
  cursor: pointer;
  border-radius: 0.3rem; // Rounded corners on the right side
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;

  &:hover {
    background: ${colors.buttonHover};
  }
`;
