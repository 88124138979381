import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import { fontSize } from "../../../assets/style/font";
import InfoBox from "../../../components/common/InfoBox";
import { Title } from "../../../assets/style/Title";
import { getPoints } from "../../../api/miniLeague/getPoints";
import { getManagerData } from "../../../api/manager/getManagerData";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataState } from "../../../state/data";
import Button from "../../../components/common/Button";
import { IoMdRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { searchState } from "../../../state/search";
import { optionState } from "../../../state/option";

const MiniLeagueRanking = () => {
  const [pointsData, setPointsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const data = useRecoilValue(dataState);
  const [search, setSearch] = useRecoilState(searchState);
  const [option, setOption] = useRecoilState(optionState);

  const handleLoad = async (entry: any, event: React.MouseEvent) => {
    event.stopPropagation(); // 이벤트 전파 막기
  };

  let navigate = useNavigate();

  const handleRowClick = (entry: any) => {
    setSearch({ ...search, managerId: entry });
    setOption({ ...option, managerMenuOption: "search" });

    navigate(`/manager`);
  };

  useEffect(() => {
    const fetchNewEntries = async () => {
      try {
        const results: any = await getPoints();
        const points = results.data;
        setPointsData(points);
      } catch (error) {
        console.error("Error fetching new entries:", error);
      }
    };

    fetchNewEntries();
  }, [loading]);

  return (
    <RankingContainer>
      <RankingTitle>FPL Universe League Ranking</RankingTitle>
      <TableWrapper>
        <RankingTable>
          <thead>
            <tr>
              <TableHeader>Rank</TableHeader>
              <TableHeader>Manager</TableHeader>
              <TableHeader>Total</TableHeader>
              <TableHeader>Round</TableHeader>
              <TableHeader>Transfer Made</TableHeader>
              <TableHeader>World Rank</TableHeader>
              <TableHeader>Captain</TableHeader>
              <TableHeader>Refresh</TableHeader>
            </tr>
          </thead>
          <tbody>
            {pointsData.map((data: any) => (
              <TableRow key={data.rank} onClick={() => handleRowClick(data.entry)}>
                <TableData>{data.rank}</TableData>
                <TableData>{data.entry_name}</TableData>
                <TableData>{data.total}</TableData>
                <TableData>{data.event_total}</TableData>
                <TableData>{data.total_transfers}</TableData>
                {data.overall_rank ? <TableData>{data.overall_rank}</TableData> : <TableData></TableData>}
                {data.current_captain ? (
                  <TableData>{data.current_captain}</TableData>
                ) : (
                  <TableData>
                    <LoadButton onClick={(event) => handleLoad(data.entry, event)}>
                      {loading ? "Loading..." : "Load"}
                    </LoadButton>
                  </TableData>
                )}
                <TableData>
                  <LoadButton onClick={(event) => handleLoad(data.entry, event)}>
                    {loading ? "Loading..." : <IoMdRefresh color={colors.textBlack} style={{ cursor: "pointer" }} />}
                  </LoadButton>
                </TableData>
              </TableRow>
            ))}
          </tbody>
        </RankingTable>
      </TableWrapper>
    </RankingContainer>
  );
};

export default MiniLeagueRanking;

const RankingContainer = styled(InfoBox)`
  background-color: ${colors.rankingBackground};
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem 1rem;
  text-align: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;

  @media (max-width: 768px) {
    margin: 0.5rem 0;
    width: 100%;
    padding: 0rem;
  }
`;

const RankingTitle = styled(Title)`
  color: ${colors.textBlack};
  margin-bottom: 1rem;
  font-size: ${fontSize.media_large.large};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.large};
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: auto;
`;

const RankingTable = styled.table`
  width: 90%;
  margin: auto;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: ${colors.tableHeader};
  color: ${colors.textWhite};
  padding: 0.2rem 0.4rem;
  font-size: ${fontSize.media_large.small};
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
    padding: 0.2rem;
  }
`;

const TableRow = styled.tr`
  cursor: pointer;
  transition: background-color 0.3s;

  &:nth-child(even) {
    background-color: ${colors.infoBoxBackground};
  }

  &:hover {
    background-color: ${colors.tableRowHover};
  }
`;

const TableData = styled.td`
  padding: 0.8rem;
  color: ${colors.textBlack};
  text-align: center;
  border-bottom: 1px solid ${colors.baseBorder};
  font-size: ${fontSize.media_large.small};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
    padding: 0.5rem;
  }
`;

const LoadButton = styled(Button)`
  padding: 0.2rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${colors.buttonHover};
  }
`;
