import { useState } from "react";
import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import { getTeamLogoById } from "../../../utils/getTeamLogoById";
import PlayerModal from "../../../components/PlayerModal";
import soccerBallLogo from "../../../assets/icon/soccer_ball.svg";
import { fontSize, NumericText } from "../../../assets/style/font";
import { GiChelseaBoot } from "react-icons/gi";

interface InnerPlayerDataProps {
  data: any;
  teamInfo: any;
  currentRound: number;
  option: string;
}

const InnerPlayerData: React.FC<InnerPlayerDataProps> = ({ data, teamInfo, currentRound, option }) => {
  const [playerData, setPlayerData] = useState({});
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const [teamData, setTeamData] = useState({});

  const handleOpenPlayerModal = (data: any) => {
    setIsPlayerModalOpen(true);
    setPlayerData(data);
    let teamData: any;
    for (let i = 0; i < teamInfo.length; i++) {
      if (teamInfo[i].id === data.team) {
        teamData = teamInfo[i];
        break;
      }
    }
    setTeamData(teamData);
  };

  const getName = (data: any) => {
    if (data.first_name.length + data.second_name.length > 15) {
      return data.web_name;
    } else {
      return `${data.first_name} ${data.second_name}`;
    }
  };

  const getMainData = (data: any) => {
    if (option === "event_point") {
      return data.event_points;
    } else if (option === "recent_point") {
      return Math.floor(data.recent_total_points);
    } else if (option === "total_point") {
      return data.total_points;
    } else if (option === "goals") {
      return data.goals_scored;
    } else if (option === "assists") {
      return data.assists;
    } else if (option === "selection") {
      return data.selected_by_percent;
    } else if (option === "bonus") {
      return data.bonus;
    } else if (option === "expected_goals") {
      return data.expected_goals;
    } else if (option === "expected_assists") {
      return data.expected_assists;
    } else if (option === "expected_goal_involvements") {
      return data.expected_goal_involvements;
    }
  };

  const handleClosePlayerModal = () => {
    setIsPlayerModalOpen(false);
  };

  return (
    <DataContainer onClick={() => handleOpenPlayerModal(data)}>
      {isPlayerModalOpen && (
        <PlayerModal
          isOpen={isPlayerModalOpen}
          onClose={handleClosePlayerModal}
          playerInfo={playerData}
          allTeamInfo={teamInfo}
          teamInfo={teamData}
          currentRound={currentRound}
        />
      )}
      <LeftData>
        <TeamLogo src={getTeamLogoById(teamInfo, data.team_code, "code")} alt="logo"></TeamLogo>
        <LargeScreenOnly>{getName(data)} </LargeScreenOnly>
        <SmallScreenOnly>{data.web_name} </SmallScreenOnly>
      </LeftData>
      <RightData>
        {option === "event_point" && (
          <>
            {data.round_goals ? (
              <InnerRightData>
                {data.round_goals} <SoccerBallLogo src={soccerBallLogo}></SoccerBallLogo>
              </InnerRightData>
            ) : (
              ""
            )}

            {data.round_assists ? (
              <InnerRightData>
                {data.round_assists} <GiChelseaBoot />
              </InnerRightData>
            ) : (
              ""
            )}
          </>
        )}
        {(option === "total_point" ||
          option === "expected_goals" ||
          option === "expected_assists" ||
          option === "expected_goal_involvements") && (
          <>
            {option !== "expected_assists" && data.goals_scored ? (
              <InnerRightData>
                {data.goals_scored} <SoccerBallLogo src={soccerBallLogo}></SoccerBallLogo>
              </InnerRightData>
            ) : (
              ""
            )}

            {option !== "expected_goals" && data.assists ? (
              <InnerRightData>
                {data.assists} <GiChelseaBoot />
              </InnerRightData>
            ) : (
              ""
            )}
          </>
        )}{" "}
        {option === "recent_point" && (
          <>
            {data.recent_goals ? (
              <InnerRightData>
                {data.recent_goals} <SoccerBallLogo src={soccerBallLogo}></SoccerBallLogo>
              </InnerRightData>
            ) : (
              ""
            )}

            {data.recent_assists ? (
              <InnerRightData>
                {data.recent_assists} <GiChelseaBoot />
              </InnerRightData>
            ) : (
              ""
            )}
          </>
        )}
        <span>{getMainData(data)}</span>
      </RightData>
    </DataContainer>
  );
};

export default InnerPlayerData;

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.3rem;
  padding: 0.2rem;
  border-radius: 0.5rem;
  color: ${colors.baseText};

  &:hover {
    cursor: pointer;
    background-color: ${colors.buttonHover};
  }
`;

const TeamLogo = styled.img`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
`;

const SoccerBallLogo = styled.img`
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
`;

const SoccerBootLogo = styled.img`
  width: 1.3rem;
  height: 1.5rem;
  margin-left: 5px;
`;

const LeftData = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: 0.5rem;
  width: 50%;
`;

const RightData = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  span {
    width: 2rem;
    text-align: right;
    font-size: 1.1rem;
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
  }
`;

const InnerRightData = styled(NumericText)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 2.2rem;
  margin-right: 1rem;
`;

const SmallScreenOnly = styled.span`
  text-align: left;
  font-size: ${fontSize.media_large.small};

  @media (min-width: 1078px) {
    display: none;
  }
`;

const LargeScreenOnly = styled.span`
  text-align: left;
  font-size: ${fontSize.media_large.small};

  @media (max-width: 1077px) {
    display: none;
  }
`;
