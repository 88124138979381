export const ComponentWidth = {
  HomeComponent: {
    media_small: {},
    media_medium: "25rem",
    media_large: {
      small: "20rem",
      medium: "30rem",
      large: "40rem",
    },
  },
};
