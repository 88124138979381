// Banner.js

import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import colors from "../../../assets/colors/colors";
import PlayerModal from "../../../components/PlayerModal";
import { getPlayerComment, getPlayerNickname } from "../api/getPlayerNickname";
import { fontSize, NumericText } from "../../../assets/style/font";
import { Title } from "../../../assets/style/Title";
import InfoBox from "../../../components/common/InfoBox";
import { getWeeklyPointersApi } from "../api/getTopData";

interface PlayerData {
  allTeamInfo: any; // Replace 'any' with the actual type of your teamData
  currentRound: number;
}

const BestPlayer: React.FC<PlayerData> = ({ allTeamInfo, currentRound }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playerCode, setPlayerCode] = useState(0);
  const [playerImgSrc, setPlayerImgSrc] = useState("");
  const [playerData, setPlayerData] = useState<any>(null);
  const [teamData, setTeamData] = useState<any>(null);
  const [nickname, setNickname] = useState<string>("");
  const [playerInfo, setPlayerInfo] = useState<any[]>([]);

  const getTopPlayer = async () => {
    try {
      const response = await getWeeklyPointersApi("");
      setPlayerInfo(response);
    } catch (error) {
      console.error("Error fetching top player:", error);
    }
  };

  // const getData = async () => {
  //   try {
  //     const nicknameResponse = await getPlayerNickname(playerInfo[0].id);
  //     setNickname(nicknameResponse.data ? nicknameResponse.data : "");
  //   } catch (error) {
  //     console.error("Error fetching nickname:", error);
  //   }
  // };

  useEffect(() => {
    getTopPlayer();
  }, []);

  useEffect(() => {
    if (playerInfo.length > 0) {
      setPlayerCode(playerInfo[0].code);
      setPlayerImgSrc(
        `https://resources.premierleague.com/premierleague/photos/players/110x140/p${playerInfo[0].code}.png`
      );
      // getData();
    }
  }, [playerInfo, allTeamInfo]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    let teamData: any;
    for (let i = 0; i < allTeamInfo.length; i++) {
      if (allTeamInfo[i].id === playerInfo[0].team) {
        teamData = allTeamInfo[i];
        break;
      }
    }
    setTeamData(teamData);
    setPlayerData(playerInfo[0]);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Container>
      {isModalOpen && (
        <PlayerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          playerInfo={playerData}
          allTeamInfo={allTeamInfo}
          teamInfo={teamData}
          currentRound={currentRound}
        />
      )}

      <Components onClick={() => handleOpenModal()}>
        <BestPlayerTitle>Round Best Player</BestPlayerTitle>
        <BestPlayerContainer>
          <ImgContainer>
            <PlayerImg src={playerImgSrc}></PlayerImg>
            {nickname && <Nickname>{nickname}</Nickname>}
          </ImgContainer>
          {playerInfo.length > 0 && (
            <PlayerInfo>
              <Name>
                {playerInfo[0].first_name} {playerInfo[0].second_name}
              </Name>
              <BasicInfo>
                <InfoText>Round Points</InfoText>
                <InfoValue>{playerInfo[0].event_points}</InfoValue>
              </BasicInfo>
              <BasicInfo>
                <InfoText>Total Points</InfoText>
                <InfoValue>{playerInfo[0].total_points}</InfoValue>
              </BasicInfo>
              <BasicInfo>
                <InfoText>Selected By</InfoText>
                <InfoValue>{playerInfo[0].selected_by_percent}%</InfoValue>
              </BasicInfo>
            </PlayerInfo>
          )}
        </BestPlayerContainer>
      </Components>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 45%;
  margin: 1rem;

  &:hover {
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BestPlayerTitle = styled.div`
  margin-top: 0.5rem;
  color: ${colors.baseText};
  font-size: ${fontSize.media_large.medium};
  font-weight: bold;
`;

const Components = styled.div`
  background: linear-gradient(145deg, ${colors.bestPlayerBackground}, #b8dddd);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  color: #fff;
  text-align: center;
  width: 100%;

  border-radius: 1rem;

  @media (max-width: 1268px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const BestPlayerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;

  @media (max-width: 500px) {
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;

  @media (max-width: 500px) {
  }
`;

const PlayerImg = styled.img`
  width: 8rem;
  margin-left: 1rem;

  @media (max-width: 500px) {
    width: 6rem;
    margin-left: 0;
  }
`;

const Nickname = styled.div`
  color: ${colors.baseColor};
  font-weight: 600;
  font-size: ${fontSize.media_large.medium};
  margin-top: 0.5rem;
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 2rem;
  padding: 1rem;

  @media (max-width: 500px) {
    margin-right: 0;
  }
`;

const Name = styled.div`
  font-size: ${fontSize.media_large.medium};
  color: ${colors.baseColor};
  margin-bottom: 0.5rem;

  @media (max-width: 500px) {
    font-size: ${fontSize.media_small.medium};
  }
`;

const BasicInfo = styled.div`
  color: ${colors.baseColor};
  font-size: ${fontSize.media_large.small};

  margin-bottom: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 500px) {
    font-size: ${fontSize.media_small.small};
  }
`;

const InfoText = styled.span`
  font-weight: bold;
  margin-right: 1rem;
`;

const InfoValue = styled(NumericText)`
  margin-left: 0.5rem;
`;

export default BestPlayer;
