import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../assets/colors/colors";
import { fontSize } from "../assets/style/font";
import InfoBox from "./common/InfoBox";
import { Title } from "../assets/style/Title";
import { getPoints } from "../api/miniLeague/getPoints";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataState } from "../state/data";
import Button from "./common/Button";
import { IoMdRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { searchState } from "../state/search";
import { optionState } from "../state/option";
import { getManagerData } from "../api/manager/getManagerData";
import CaptainBarChart from "./CaptainBarChart";

const REFRESH_COOLDOWN_TIME = 300000; // 5분 = 300,000 밀리초

const MiniLeagueRanking = () => {
  const [pointsData, setPointsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false); // 쿨타임 상태 관리
  const [cooldownTimeLeft, setCooldownTimeLeft] = useState(0); // 남은 쿨타임
  const data = useRecoilValue(dataState);
  const [search, setSearch] = useRecoilState(searchState);
  const [option, setOption] = useRecoilState(optionState);

  const handleLoad = async (entry: any, event: React.MouseEvent) => {
    event.stopPropagation(); // 이벤트 전파 막기
    setLoading(true);
    await getManagerData(entry, data.currentRound);
    setLoading(false);
  };

  const handleRefreshAll = async () => {
    setIsCooldown(true); // 쿨타임 시작
    setCooldownTimeLeft(REFRESH_COOLDOWN_TIME); // 쿨타임 시간 설정

    setLoading(true);
    for (let point of pointsData) {
      console.log(point.entry);
      await getManagerData(point.entry, data.currentRound); // 모든 매니저 데이터 갱신
    }
    setLoading(false);
  };

  // 쿨타임 타이머 관리
  useEffect(() => {
    if (isCooldown && cooldownTimeLeft > 0) {
      const interval = setInterval(() => {
        setCooldownTimeLeft((prev) => prev - 1000);
      }, 1000);

      return () => clearInterval(interval); // 컴포넌트가 언마운트될 때 타이머 클리어
    } else if (cooldownTimeLeft <= 0) {
      setIsCooldown(false); // 쿨타임이 끝나면 다시 버튼 활성화
    }
  }, [isCooldown, cooldownTimeLeft]);

  // captain 선택에 대한 빈도 계산
  const captainStats = pointsData.reduce((acc: any, data: any) => {
    const captain = data.current_captain;
    if (captain) {
      acc[captain] = (acc[captain] || 0) + 1;
    }
    return acc;
  }, {});

  let navigate = useNavigate();

  const handleRowClick = (entry: any) => {
    setSearch({ ...search, managerId: entry });
    setOption({ ...option, managerMenuOption: "search" });
    navigate(`/manager`);
  };

  useEffect(() => {
    const fetchNewEntries = async () => {
      try {
        const results: any = await getPoints();
        const points = results.data;
        setPointsData(points);
      } catch (error) {
        console.error("Error fetching new entries:", error);
      }
    };
    fetchNewEntries();
  }, [loading]);

  return (
    <RankingContainer>
      <RankingTitle>FPL Universe League Ranking</RankingTitle>

      <TableWrapper>
        <RankingTable>
          <thead>
            <tr>
              <TableHeader>Rank</TableHeader>
              <TableHeader>Manager</TableHeader>
              <TableHeader>Total</TableHeader>
              <TableHeader>Round</TableHeader>
              <TableHeader>Transfers</TableHeader>
              <TableHeader>World Rank</TableHeader>
              <TableHeader>Captain</TableHeader>
              <TableHeader>
                {" "}
                <RefreshAllButton
                  onClick={handleRefreshAll}
                  disabled={isCooldown || loading} // 쿨타임 동안 버튼 비활성화
                >
                  {isCooldown
                    ? `Cooldown: ${Math.ceil(cooldownTimeLeft / 1000)}s` // 쿨타임이 남았을 경우 남은 시간 표시
                    : "Refresh All"}
                </RefreshAllButton>
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {pointsData.map((data: any) => (
              <TableRow key={data.rank} onClick={() => handleRowClick(data.entry)}>
                <TableData>{data.rank}</TableData>
                <TableData>{data.entry_name}</TableData>
                <TableData>{data.total}</TableData>
                <TableData>{data.event_total}</TableData>
                <TableData>{data.total_transfers}</TableData>
                {data.overall_rank ? <TableData>{data.overall_rank}</TableData> : <TableData>—</TableData>}
                {data.current_captain ? (
                  <TableData>{data.current_captain}</TableData>
                ) : (
                  <TableData>
                    <LoadButton onClick={(event) => handleLoad(data.entry, event)}>
                      {loading ? "Loading..." : "Load"}
                    </LoadButton>
                  </TableData>
                )}
                <TableData>
                  <RefreshIcon onClick={(event) => handleLoad(data.entry, event)}>
                    {loading ? "..." : <IoMdRefresh />}
                  </RefreshIcon>
                </TableData>
              </TableRow>
            ))}
          </tbody>
        </RankingTable>
      </TableWrapper>

      {/* Captain 통계 차트 추가 */}
      <RankingTitle
        style={{
          marginTop: "2rem",
        }}
      >
        Captain Picks
      </RankingTitle>
      <CaptainBarChart data={captainStats} />
    </RankingContainer>
  );
};

export default MiniLeagueRanking;

const RefreshAllButton = styled(Button)`
  background-color: ${colors.buttonBase2};
  color: ${colors.textWhite};
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;
  font-size: ${fontSize.media_large.medium};
  text-align: right;

  &:disabled {
    background-color: ${colors.disabledButton};
    cursor: not-allowed;
  }

  &:hover:enabled {
    background-color: ${colors.buttonHover};
  }

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
    padding: 0.5rem 1rem;
  }
`;

const RankingContainer = styled(InfoBox)`
  background-color: ${colors.rankingBackground};
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 95%;
  height: auto;

  @media (max-width: 768px) {
    padding: 0rem;
    margin: 0rem 0;
    width: 100%;
  }
`;

const RankingTitle = styled(Title)`
  color: ${colors.textBlack};
  margin-bottom: 1.5rem;
  font-size: ${fontSize.media_large.large};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.large};
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const RankingTable = styled.table`
  width: 100%;
  margin: auto;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: ${colors.tableHeader};
  color: ${colors.textWhite};
  padding: 1rem 0.5rem;
  font-size: ${fontSize.media_large.small};
  text-transform: uppercase;
  border-bottom: 2px solid ${colors.tableBorder};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
    padding: 0.2rem;
  }
`;

const TableRow = styled.tr`
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:nth-child(even) {
    background-color: ${colors.tableRow};
  }

  &:hover {
    background-color: ${colors.tableRowHover};
  }
`;

const TableData = styled.td`
  padding: 0.5rem;
  color: ${colors.textBlack};
  text-align: center;
  border-bottom: 1px solid ${colors.baseBorder};
  font-size: ${fontSize.media_large.small};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
    padding: 0.3rem;
  }
`;

const LoadButton = styled(Button)`
  padding: 0.4rem;
  border-radius: 0.5rem;
  font-size: ${fontSize.media_large.small};
  cursor: pointer;
  background-color: ${colors.buttonBase};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${colors.buttonHover};
    color: ${colors.textWhite};
  }

  @media (max-width: 768px) {
    padding: 0.2rem;
    font-size: ${fontSize.media_small.small};
  }
`;

const RefreshIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.2rem;
  color: ${colors.textBlack};
  transition: color 0.3s;

  &:hover {
    color: ${colors.buttonHover};
  }
`;
