import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { accountState } from "../state/account";
import colors from "../assets/colors/colors";
import logo from "../assets/logo9.png";
import { FaHome, FaQuestion, FaRegUser, FaTimes } from "react-icons/fa";
import { GiSoccerKick } from "react-icons/gi";
import { GrUserManager } from "react-icons/gr";
import { RiTeamLine } from "react-icons/ri";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { optionState } from "../state/option";
import { Link } from "react-router-dom";
import PromotionBanner from "./PromotionBanner";
import { fontSize } from "../assets/style/font";
import { SiPremierleague } from "react-icons/si";

enum MenuOptions {
  PLAYER = "player",
  MANAGER = "manager",
}

interface MenuStates {
  player: boolean;
  manager: boolean;
}

interface StyledNavbarProps {
  expanded: boolean;
}

interface NavLinksProps {
  isNavExpanded: boolean;
}

interface NavLinkProps {
  isLogin?: boolean;
}

interface SubMenuProps {
  menuExpanded: boolean;
}

const Navbar: React.FC = () => {
  const [isNavExpanded, setIsNavExpanded] = useState<boolean>(false);
  const [menuStates, setMenuStates] = useState<MenuStates>({
    player: false,
    manager: false,
  });
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(true);
  const accountInfo = useRecoilValue(accountState);
  const [options, setOptions] = useRecoilState(optionState);

  const leagueCode = "t0fhxl";

  const toggleMenu = (menu: MenuOptions) => {
    setMenuStates((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const handleNavClick = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(leagueCode).then(() => {
      alert("Code copied to clipboard!");
    });
  };

  const handleSubLinkClick = (menuOption: keyof typeof options, searchType: any) => {
    setOptions((prevState: any) => ({
      ...prevState,
      [menuOption]: searchType,
    }));
    handleNavClick();
  };

  return (
    <NavbarContainer expanded={isNavExpanded}>
      <Head>
        <Logo href="/">
          <LogoImg src={logo} alt="logo" />
          <span>FPL Universe</span>
        </Logo>
        <ToggleMenu onClick={handleNavClick}>☰</ToggleMenu>
      </Head>
      <StyledNavbar expanded={isNavExpanded}>
        <NavLinks isNavExpanded={isNavExpanded}>
          <NavLinkItem to="/" icon={<FaHome />} label="Home" onClick={handleNavClick} />
          <NavLinkItem to="/league" icon={<SiPremierleague />} label="FPL Universe League" onClick={handleNavClick} />
          <ExpandableMenu
            icon={<GiSoccerKick />}
            label="Player"
            isExpanded={menuStates.player}
            onToggle={() => toggleMenu(MenuOptions.PLAYER)}
          >
            {[
              { label: "BEST Picks", menuOption: 0 },
              { label: "Best Differentials", menuOption: 1 },
              { label: "Top Transfer In", menuOption: 2 },
              { label: "Top Transfer Out", menuOption: 3 },
              { label: "Most underpriced Forward", menuOption: 4 },
              { label: "Most underpriced Midfielder", menuOption: 5 },
              { label: "Most underpriced Defender", menuOption: 6 },
              { label: "Most underpriced Goalkeeper", menuOption: 7 },
            ].map((item, index) => (
              <SubLinkItem
                key={index}
                to="/player"
                label={item.label}
                onClick={() => handleSubLinkClick("playerMenuOption", item.menuOption)}
              />
            ))}
          </ExpandableMenu>
          <NavLinkItem to="/team" icon={<RiTeamLine />} label="Team" onClick={handleNavClick} />
          <ExpandableMenu
            icon={<GrUserManager />}
            label="Manager"
            isExpanded={menuStates.manager}
            onToggle={() => toggleMenu(MenuOptions.MANAGER)}
          >
            <SubLinkItem
              to="/manager"
              label="Search Manager"
              onClick={() => handleSubLinkClick("managerMenuOption", "search")}
            />
            <SubLinkItem
              to="/manager"
              label="Top Managers"
              onClick={() => handleSubLinkClick("managerMenuOption", "topWorld")}
            />
          </ExpandableMenu>
          <NavLinkItem to="/test" icon={<FaQuestion />} label="If you were PL Manager.." onClick={handleNavClick} />

          {/* {accountInfo.isLoggedIn ? (
            <NavLinkItem to="/mypage" icon={<FaRegUser />} label={accountInfo.username} onClick={handleNavClick} />
          ) : (
            <NavLinkItem to="/login" icon={<CgLogIn />} label="Login" isLogin onClick={handleNavClick} />
          )} */}
        </NavLinks>
        {isBannerVisible && <PromotionBanner />}
      </StyledNavbar>
    </NavbarContainer>
  );
};

interface NavLinkItemProps {
  to: string;
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  isLogin?: boolean;
}

const NavLinkItem: React.FC<NavLinkItemProps> = ({ to, icon, label, onClick, isLogin }) => (
  <NavLink to={to} onClick={onClick} isLogin={isLogin}>
    <Menu>
      <MenuLeft>
        <Icon>{icon}</Icon>
        <span>{label}</span>
      </MenuLeft>
    </Menu>
  </NavLink>
);

interface ExpandableMenuProps {
  icon: React.ReactNode;
  label: string;
  isExpanded: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}

const ExpandableMenu: React.FC<ExpandableMenuProps> = ({ icon, label, isExpanded, onToggle, children }) => (
  <NonNavLink>
    <Menu onClick={onToggle}>
      <MenuLeft>
        <Icon>{icon}</Icon>
        <span>{label}</span>
      </MenuLeft>
      <MenuRight>{isExpanded ? <MdExpandLess /> : <MdExpandMore />}</MenuRight>
    </Menu>
    <SubMenu menuExpanded={isExpanded}>{children}</SubMenu>
  </NonNavLink>
);

interface SubLinkItemProps {
  to: string;
  label: string;
  onClick: () => void;
}

const SubLinkItem: React.FC<SubLinkItemProps> = ({ to, label, onClick }) => (
  <SubLink to={to} onClick={onClick}>
    <span>{label}</span>
  </SubLink>
);

// Styled components remain the same
// ...

export default Navbar;

const NavbarContainer = styled.div<StyledNavbarProps>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${colors.navBackground};
  color: ${colors.textWhite};
  width: 16rem;
  transition: width 0.3s ease;

  @media (max-width: 768px) {
    width: 100%;
    height: ${({ expanded }) => (expanded ? "100vh" : "5rem")};
    z-index: 1000;
  }
`;

const StyledNavbar = styled.nav<StyledNavbarProps>`
  display: flex;
  flex-direction: column;
  transition: height 0.3s ease;
  overflow: hidden;
  padding: 0 1rem;

  @media (max-width: 768px) {
    height: ${({ expanded }) => (expanded ? "calc(100vh - 5rem)" : "0")};
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  height: 5rem;
  background-color: ${colors.baseColor};
`;

const ToggleMenu = styled.div`
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
  margin-right: -1rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Logo = styled.a`
  display: flex;
  margin-left: -1rem;
  align-items: center;
  text-decoration: none;
  color: ${colors.textWhite};

  span {
    font-size: 1.3rem;
  }

  @media (max-width: 768px) {
  }
`;

const LogoImg = styled.img`
  width: 2.5rem;
  margin-right: 0.5rem;
`;

const NavLinks = styled.div<NavLinksProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;

  @media (max-width: 768px) {
    align-items: ${({ isNavExpanded }) => (isNavExpanded ? "flex-start" : "center")};
  }
`;

const NavLink = styled(Link)<NavLinkProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.7rem 0;
  text-decoration: none;
  border-radius: 5px;
  color: ${colors.textWhite};

  span {
    margin-left: 10px;
  }
`;

const NonNavLink = styled.div`
  width: 100%;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: ${colors.baseHover};
  }
`;

const LoginMenu = styled(Menu)`
  background-color: ${colors.buttonLogin};
  padding: 0.5rem 0.5rem;
  color: ${colors.textBlack};

  &:hover {
    background-color: ${colors.buttonLoginHover};
    color: ${colors.textWhite};
  }
`;

const MenuLeft = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 1rem;
    font-size: 0.9rem;
  }
`;

const MenuRight = styled.div`
  display: flex;
  align-items: center;
`;

const SubMenu = styled.div<SubMenuProps>`
  display: ${({ menuExpanded }) => (menuExpanded ? "flex" : "none")};
  flex-direction: column;
  padding-left: 20px;
`;

const SubLink = styled(Link)`
  padding: 10px 0;
  text-decoration: none;
  border-radius: 5px;
  color: ${colors.textWhite};

  span {
    margin-left: 1rem;
    font-size: ${fontSize.media_large.small};
  }
  &:hover {
    background-color: ${colors.baseHover};
  }
`;

const Icon = styled.div`
  margin-right: 10px;
  font-size: 1.2rem;
`;
