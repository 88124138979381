import React from "react";
import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import { Title } from "../../../assets/style/Title";
import { FaArrowUp, FaArrowDown, FaEquals } from "react-icons/fa";
import InfoBox from "../../../components/common/InfoBox";

const EventHistory = ({ managerData }: any) => {
  return (
    <Container>
      <EventHistoryTitle>Event History</EventHistoryTitle>
      {managerData ? (
        <TableWrapper>
          <HistoryTable>
            <thead>
              <TableHeaderRow>
                <TableHeader>Event</TableHeader>
                <TableHeader>Event Rank</TableHeader>
                <TableHeader>Points</TableHeader>
                <TableHeader>Bench Points</TableHeader>
                <TableHeader>Total Points</TableHeader>
                <TableHeader>Top %</TableHeader>
                {/* <TableHeader>Performance</TableHeader> */}
              </TableHeaderRow>
            </thead>
            <tbody>
              {managerData.ranks.map((event: any, index: any) => (
                <TableRow key={index}>
                  <TableData>{event.event}</TableData>
                  <TableData>{event.rank ? event.rank.toLocaleString() : ""}</TableData>
                  <TableData>{event.points}</TableData>
                  <TableData>{event.bench_points}</TableData>
                  <TableData>{event.total_points}</TableData>
                  <TableData>
                    <TopPercentage>
                      TOP {event.percentage}%
                      <PercentileBarWrapper>
                        <PercentileBar width={event.percentage} />
                      </PercentileBarWrapper>
                    </TopPercentage>
                  </TableData>
                  {/* <TableData>
                    <PerformanceIndicator rank={event.rank_sort}>
                      {event.rank_sort < 0 ? (
                        <FaArrowUp color="green" />
                      ) : event.rank_sort > 0 ? (
                        <FaArrowDown color="red" />
                      ) : (
                        <FaEquals color="gray" />
                      )}
                    </PerformanceIndicator>
                  </TableData> */}
                </TableRow>
              ))}
            </tbody>
          </HistoryTable>
        </TableWrapper>
      ) : (
        <LoadingMessage>Loading...</LoadingMessage>
      )}
    </Container>
  );
};

export default EventHistory;

const Container = styled.div`
  margin-top: 2rem;
  width: 100%;
`;

const EventHistoryTitle = styled(Title)`
  font-size: 1.5rem;
  color: ${colors.textBlack};
  margin-bottom: 1rem;
  text-align: center;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const HistoryTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  background-color: ${colors.infoBoxBackground};
`;

const TableHeaderRow = styled.tr`
  background-color: ${colors.tableHeader};
`;

const TableHeader = styled.th`
  padding: 0.75rem 1rem;
  color: ${colors.textWhite};
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: ${colors.tableRow};
  }

  &:hover {
    background-color: ${colors.tableRowHover};
  }
`;

const TableData = styled.td`
  padding: 0.75rem 1rem;
  color: ${colors.textBlack};
  text-align: center;
  font-size: 0.9rem;
  border-bottom: 1px solid ${colors.baseBorder};

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
`;

const TopPercentage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PercentileBarWrapper = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${colors.baseBorder};
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
`;

const PercentileBar = styled.div<{ width: number }>`
  height: 100%;
  background-color: ${colors.success};
  width: ${(props) => props.width}%;
`;

const PerformanceIndicator = styled.div<{ rank: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.5;
  font-size: 1.2rem;

  svg {
    margin-left: 5px;
  }
`;

const LoadingMessage = styled.p`
  text-align: center;
  color: ${colors.textBlack};
  font-size: 1rem;
`;
