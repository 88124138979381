import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { checkJWTExpire } from "../../../utils/checkJWT";
import { useRecoilState } from "recoil";
import { accountState } from "../../../state/account";
import colors from "../../../assets/colors/colors";
import Select, { SingleValue, StylesConfig } from "react-select";

// Define the type for player options
type PlayerOption = {
  value: number;
  label: string;
};

const PlayerComment: React.FC = () => {
  const [account, setAccountState] = useRecoilState(accountState);
  const [playerId, setPlayerId] = useState<number | null>(null);
  const [type, setType] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [playerList, setPlayerList] = useState<Array<any>>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredPlayers = searchTerm
    ? playerList.filter((player) => player.web_name.toLowerCase().includes(searchTerm.toLowerCase()))
    : playerList;

  const getAllPlayers = async () => {
    try {
      const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
      const response = await axios.get(`http://${serverUrl}/api/player`);

      // sort player list by total points
      response.data.sort((a: any, b: any) => b.total_points - a.total_points);
      setPlayerList(response.data);
    } catch (error) {
      console.error("Error fetching data from FPL:", error);
    }
  };

  useEffect(() => {
    getAllPlayers();
  }, []);

  const customStyles: StylesConfig<PlayerOption, false> = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: colors.baseColor,
      width: "15rem",
      "&:hover": { borderColor: colors.baseColor },
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isFocused ? colors.baseColor : isSelected ? colors.textBlack : undefined,
      color: isFocused || isSelected ? "white" : "black",
      cursor: "pointer",
    }),
    // Add more custom styles if needed
  };

  // Handler when an option is selected
  const handlePlayerChange = (selectedOption: any) => {
    setPlayerId(selectedOption.value);
  };

  const handleSubmit = async () => {
    try {
      const token: string = localStorage.getItem("token") ? localStorage.getItem("token") : account.token;

      const jwtCheck = checkJWTExpire(token);
      if (!jwtCheck) {
        alert("Your session has expired. Please log in again.");
        localStorage.clear();
        setAccountState({
          username: "",
          email: "",
          fplId: 0,
          isLoggedIn: false,
        });
        window.location.href = "/";
      }
      const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
      const request = process.env.REACT_APP_SERVER_TYPE === "PROD" ? "https" : "http";

      console.log(playerId, content, type);

      if (type === "nickname") {
        const response = await axios.post(
          `${request}://${serverUrl}/api/player/nickname`,
          {
            playerId: playerId,
            nickname: content,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(response);
        alert("Nickname Set");
      } else if (type === "comment") {
        const response = await axios.post(
          `${request}://${serverUrl}/api/player/comment`,
          {
            playerId: playerId,
            comment: content,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response);
        alert("Comment Set");
      }
    } catch (error) {
      console.error("Error creating quiz:", error);
    }
  };

  // Prepare options for react-select
  const playerOptions: PlayerOption[] = playerList.map((player) => ({
    value: player.id,
    label: player.web_name,
  }));

  return (
    <Container>
      <Subject>Submit Player Information</Subject>
      <label htmlFor="playerSearch">Player:</label>
      <Select
        styles={customStyles}
        options={playerOptions}
        onChange={handlePlayerChange}
        placeholder="Search for a player..."
      />
      <Subject>Type</Subject>
      <TypeSelect onChange={(e) => setType(e.target.value)}>
        <option value="">Select Type</option>
        <option value="nickname">Nickname</option>
        <option value="comment">Comment</option>
      </TypeSelect>
      {type && (
        <Content placeholder={`Enter ${type}...`} value={content} onChange={(e) => setContent(e.target.value)} />
      )}
      <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
    </Container>
  );
};

// Styled-components remain the same

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  background-color: ${colors.baseColor};
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Subject = styled.h1`
  font-size: 1.5rem;
  color: ${colors.baseColor};
  margin: 10px 0;
`;

const PlayerSelect = styled.select`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid ${colors.baseColor};
  border-radius: 5px;
  width: 80%;
  background-color: ${colors.baseText};
  color: ${colors.baseColor};

  &:focus {
    outline: none;
    border-color: ${colors.baseColor};
  }
`;

const TypeSelect = styled(PlayerSelect)``; // Inherits the same styles as PlayerSelect

const Content = styled.textarea`
  width: 80%;
  height: 100px;
  padding: 10px;
  border: 1px solid ${colors.baseColor};
  border-radius: 5px;
  margin-bottom: 20px;
  font-family: inherit;
  font-size: 1rem;
  background-color: ${colors.baseText};
  color: ${colors.baseColor};

  &:focus {
    outline: none;
    border-color: ${colors.baseColor};
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: ${colors.baseColor};
  color: ${colors.baseText};
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${colors.baseColor};
  }
`;

export default PlayerComment;
