import styled from "styled-components";

export const Components = styled.div`
  width: 80%;
  margin: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
