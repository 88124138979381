// LoadingScreen.js

import React from "react";
import styled, { keyframes } from "styled-components";
import colors from "../../../assets/colors/colors";

const loadingAnimation = keyframes`
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
`;

const LoadingScreen = ({ location }: any) => (
  <LoadingContainer>
    <LoadingText location={location}>
      FPL Universe <br></br>
      Loading...
    </LoadingText>
    <LoadingBar location={location} />
  </LoadingContainer>
);

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${colors.loadingBackground};
  color: #fff;
`;

const LoadingBar = styled.div<{ location: string }>`
  width: 100px;
  height: 8px;
  background-color: #fff;
  margin-bottom: 10rem;
  animation: ${loadingAnimation} 1s linear infinite;
`;

const LoadingText = styled.p<{ location: string }>`
  font-size: 3rem;
  text-align: center;
  /* margin-bottom: 5rem; */

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export default LoadingScreen;
