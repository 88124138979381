import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface GraphProps {
  data: Record<string, number>; // data는 객체 형태
}

const CaptainBarChart: React.FC<GraphProps> = ({ data }) => {
  if (typeof data !== "object" || data === null) {
    console.error("Invalid data format. Expected an object.");
    return <div>Invalid data format</div>;
  }

  // 캡틴 이름과 그에 대한 선택 횟수 배열을 추출
  const categories = Object.keys(data); // 객체의 키(캡틴 이름) 배열 추출
  const values = Object.values(data); // 객체의 값(선택된 횟수) 배열 추출

  const options: ApexOptions = {
    theme: {
      mode: "light", // 테마 모드 (dark도 가능)
      palette: "palette1", // 기본 팔레트 설정
    },
    chart: {
      type: "bar", // 막대 그래프
      height: 250,
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false, // 수직 막대 그래프
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories, // 캡틴 이름들
      labels: {
        style: {
          colors: "black",
          fontFamily: "inherit",
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Managers",
        style: {
          color: "black",
          fontFamily: "inherit",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val: number) => `${val} Managers`,
      },
    },
    responsive: [
      {
        breakpoint: 800,
        options: {
          chart: {
            height: 250,
          },
          xaxis: {
            tickAmount: 5,
          },
        },
      },
    ],
    legend: {
      labels: {
        colors: "black",
      },
    },
  };

  const series = [
    {
      name: "Number of Managers",
      data: values, // 각 캡틴이 선택된 횟수
    },
  ];

  return <ReactApexChart options={options} series={series} type="bar" height={350} />;
};

export default CaptainBarChart;
