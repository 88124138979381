import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import Container from "../../../components/common/Container";

interface QuestionProps {
  question: {
    question: string;
    answers: string[];
  };
  index: number;
  onAnswerSelected: (answer: number) => void;
}

const Question: React.FC<QuestionProps> = ({ question, index, onAnswerSelected }: QuestionProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);

  // reset answer when question changes
  useEffect(() => {
    setSelectedAnswer(null);
  }, [index]);

  const handleAnswerSelected = (answer: number) => {
    setSelectedAnswer(answer);
    onAnswerSelected(answer);
  };

  return (
    <Container>
      <QuestionText>{question.question}</QuestionText>
      <Answers>
        {question.answers.map((answer, i) => (
          <Answer key={i} onClick={() => handleAnswerSelected(i)} isSelected={selectedAnswer === i}>
            {answer}
          </Answer>
        ))}
      </Answers>
    </Container>
  );
};

export default Question;

const QuestionText = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
`;

const Answers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface AnswerProps {
  isSelected: boolean;
}

const Answer = styled.div<AnswerProps>`
  background-color: ${({ isSelected }) => (isSelected ? "#45ce7e" : "#ffffff")};
  color: ${({ isSelected }) => (isSelected ? "#ffffff" : "#000000")};
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0.5rem;
  transition: all 0.3s;
  width: 100%;

  &:hover {
    background-color: #45ce7e;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;
