import axios from "axios";

export const getNewEntries = async () => {
  try {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    const request = process.env.REACT_APP_SERVER_TYPE === "PROD" ? "https" : "http";
    const response = await axios.get(`${request}://${serverUrl}/api/miniLeague/new-entries`);
    return response;
  } catch (error) {
    console.error("Error fetching new entries:", error);
  }
};
