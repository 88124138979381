import Graph from "./Graph";
import styled from "styled-components";
interface ManagerGraphProps {
  managerData: any;
}

const ManagerGraph: React.FC<ManagerGraphProps> = ({ managerData }) => {
  return (
    <GraphContainer>
      <h2>{managerData.name}</h2>
      <Graph data={managerData} />
    </GraphContainer>
  );
};

export default ManagerGraph;

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
