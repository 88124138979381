import styled from "styled-components";
import Container from "../../components/common/Container";
import { useEffect, useState } from "react";
import Question from "./components/Question";
import { getAllQuestionAndAnswer } from "./api/getAllQuestionAndAnswer";
import { submitAnswer } from "./api/submitAnswer";
import { getManagerImgSrcByTeamId } from "../../utils/getManagerImgSrc";
import Result from "./components/Result";
import colors from "../../assets/colors/colors";
import { TestMetas } from "../../metadatas/metadatas";

function TypeTest() {
  const [started, setStarted] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([] as any[]);
  const [answers, setAnswers] = useState([] as number[]);
  const [result, setResult] = useState(null as any);
  const [submitted, setSubmitted] = useState(false);

  const getData = async () => {
    try {
      const response = await getAllQuestionAndAnswer(1);
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAnswerSelected = (answerIndex: number) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = answerIndex;
    setAnswers(newAnswers);
  };

  // fetch only at the beginning

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <TestMetas />
      <Title>If you were PL Manager.. </Title>

      {!started && (
        <>
          <EmptyImg src="https://resources.premierleague.com/premierleague/photos/players/110x140/Photo-Missing.png"></EmptyImg>
          <Description>
            Which club manager is most similar to you? <br /> Take the test to find out! <br /> (Remember that this test
            is just for fun!)
          </Description>
          <StartButton
            onClick={() => {
              setStarted(true);
            }}
          >
            Start Test
          </StartButton>
        </>
      )}
      {started && !submitted && (
        <>
          <QuestionNumber>
            Q . {questionIndex + 1} of {questions.length}
          </QuestionNumber>
          <Question
            question={{
              question: questions[questionIndex]?.question,
              answers: [
                questions[questionIndex]?.answer1,
                questions[questionIndex]?.answer2,
                questions[questionIndex]?.answer3,
                questions[questionIndex]?.answer4,
              ],
            }}
            index={questionIndex}
            onAnswerSelected={handleAnswerSelected}
          ></Question>
          <NextButton
            onClick={async () => {
              if (questionIndex < questions.length - 1 && answers[questionIndex] !== undefined) {
                setQuestionIndex(questionIndex + 1);
              } else if (questionIndex < questions.length - 1 && answers[questionIndex] === undefined) {
                alert("Please select an answer");
              } else {
                const response = await submitAnswer(1, answers);
                setResult(response.data);
                setSubmitted(true);
              }
            }}
          >
            {questionIndex === questions.length - 1 ? "Submit" : "Next"}
          </NextButton>
        </>
      )}
      {submitted && <Result result={result} />}
    </Container>
  );
}

export default TypeTest;

const Title = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.div`
  font-size: 1rem;
  margin-bottom: 2rem;
`;

const EmptyImg = styled.img`
  width: 50%;
  max-width: 15rem;
  margin-top: 1rem;
`;

const StartButton = styled.button`
  background-color: ${colors.quizBackground};
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const NextButton = styled.button`
  background-color: ${colors.baseColor};
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: ${colors.bannerBackground};
  }
`;

const QuestionNumber = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;
