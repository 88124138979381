import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../assets/colors/colors";
import teamColors from "../assets/colors/teamColors";
import { getTeamLogoByName } from "../utils/getTeamLogoById";
import { getTeamNameByCode, getTeamNameById, getTeamSymbolById } from "../utils/getTeamNameById";

interface TeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  allTeamInfo: any;
  teamInfo: any; // Replace 'any' with the actual type of your teamInfo data
  currentRound: any;
}

interface ModalContentProps {
  teamColor: string;
}

const TeamModal: React.FC<TeamModalProps> = ({ isOpen, onClose, allTeamInfo, teamInfo, currentRound }) => {
  const [teamColor, setTeamColor] = useState<string>("");
  //   console.log("teamInfo ", teamInfo);
  //   console.log("Current Round: ", currentRound);
  const getTeamColor = () => {
    try {
      setTeamColor(teamColors[teamInfo.name.toLowerCase().replace(/\s/g, "").replace("'", "")]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!teamInfo) return;
    getTeamColor();
  }, [teamInfo]);

  if (!isOpen || !teamInfo) return null;
  return (
    <ModalOverlay isOpen={isOpen} onClick={onClose}>
      <ModalContent teamColor={teamColor} onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>

        <Header>
          <LeftHeader>
            <TeamLogo src={getTeamLogoByName(teamInfo.name)} alt="logo"></TeamLogo>
            <TeamInfo>
              <Name>{teamInfo.name}</Name>
              <BasicInfo>Rank: {teamInfo.position}</BasicInfo>
            </TeamInfo>
          </LeftHeader>
          <RightHeader>
            <BasicInfo>
              <span>W</span>
              <span>{teamInfo.win}</span>
            </BasicInfo>
            <BasicInfo>
              <span>D</span>
              <span>{teamInfo.draw}</span>
            </BasicInfo>{" "}
            <BasicInfo>
              <span>L</span>
              <span>{teamInfo.loss}</span>
            </BasicInfo>
          </RightHeader>
        </Header>
        <Line></Line>
        <StatsContainer>
          <StatItem>
            <StatLabel>Total Points</StatLabel>
            <StatValue>{teamInfo.points}</StatValue>
          </StatItem>
          <StatItem>
            <StatLabel>Goal Scored</StatLabel>
            <StatValue>{teamInfo.goalsFor}</StatValue>
          </StatItem>
          <StatItem>
            <StatLabel>Goals Conceded</StatLabel>
            <StatValue>{teamInfo.goalsAgainst}</StatValue>
          </StatItem>
          <StatItem>
            <StatLabel>Diff</StatLabel>
            <StatValue>{teamInfo.goalsFor - teamInfo.goalsAgainst}</StatValue>
          </StatItem>
        </StatsContainer>

        <Line></Line>
        <AdditionalInfo>
          <AdditionalInfoTitle>Fixtures</AdditionalInfoTitle>
          <AdditionalInfoContent>
            {teamInfo.fixtures.map((fixture: any, index: number) => {
              let difficulty1 = fixture[0] ? teamInfo.fixtures_difficulty[index][0] : 0;
              let difficulty2 = fixture[1] ? teamInfo.fixtures_difficulty[index][1] : 0;
              //console.log("difficulty1", difficulty1);
              return (
                <FixturesBox key={index}>
                  {index >= currentRound ? (
                    <Box>
                      <Title>GW{index} </Title>
                      <Content>
                        <TeamName difficulty={difficulty1}>
                          {" "}
                          {fixture[0] ? getTeamSymbolById(allTeamInfo, fixture[0]) : "-"}{" "}
                        </TeamName>
                        <TeamName difficulty={difficulty2}>
                          {fixture[1] ? getTeamSymbolById(allTeamInfo, fixture[1]) : null}
                        </TeamName>
                      </Content>
                    </Box>
                  ) : (
                    <></>
                  )}
                </FixturesBox>
              );
            })}
          </AdditionalInfoContent>
        </AdditionalInfo>
      </ModalContent>
    </ModalOverlay>
  );
};

// Define a function to calculate background color based on difficulty
const getBackgroundColor = (difficulty: number): string => {
  return colors.difficulties[difficulty];
};

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: black;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 200;
`;

const ModalContent = styled.div<ModalContentProps>`
  //background-color: ;
  background: linear-gradient(180deg, ${({ teamColor }) => teamColor} 0%, #ffffff 100%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  position: relative;

  @media (max-width: 768px) {
    width: 90%;
  }
  @media (min-width: 769px) {
    width: 50%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 2rem;
`;

const LeftHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

const TeamLogo = styled.img`
  width: 10rem;
  border-radius: 25%;
  margin-bottom: 10px;

  @media (max-width: 500px) {
    width: 4rem;
  }
`;

const TeamInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;

const RightHeader = styled.div`
  display: flex;
  align-items: right;
  justify-content: space-between;
  flex-direction: column;
`;

const Name = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${colors.baseColor};
  margin-bottom: 5px;

  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
`;

const BasicInfo = styled.div`
  color: ${colors.baseColor};
  margin-bottom: 5px;
  display: flex;
  justify-content: space-evenly;

  span {
    margin-right: 5px;
    font-size: 1rem;
  }
`;

const Line = styled.hr`
  border: none;
  width: 100%;
  height: 2px;
  background-color: ${colors.baseColor};
  margin: 20px 0;
`;

const CloseButton = styled.button`
  background-color: ${colors.closeButton};
  color: #fff;
  border: none;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
`;

const StatItem = styled.div`
  width: 30%;
  height: 5rem;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
`;

const StatLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
  font-size: 1rem;
`;

const StatValue = styled.div`
  color: ${colors.baseColor};
  font-size: 1.3rem;
  font-weight: bold;
  text-align: right;
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${colors.baseColor};
`;

const AdditionalInfoTitle = styled.div`
  color: ${colors.baseColor};
  font-weight: bold;
  font-size: 1.3rem;
`;

const AdditionalInfoContent = styled.div`
  color: ${colors.baseColor};
  display: flex;
  flex-direction: row;
  overflow-x: auto; /* Enable horizontal scrolling */
  /* Optionally, you can add padding and other styles as needed */
`;

const FixturesBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
  @media (max-width: 1268px) {
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Title = styled.div`
  color: ${colors.baseColor};
  font-weight: bold;
  text-align: left;
  font-size: 1rem;
`;

const Content = styled.div`
  color: #fff;
  text-align: right;
  width: 100%;
`;

const TeamName = styled.div<{ difficulty: number }>`
  color: #fff;
  background-color: ${({ difficulty }) => getBackgroundColor(difficulty)}; // Dynamic background color
  text-align: center;
  font-weight: bold;
`;

export default TeamModal;
