import styled from "styled-components";
import colors from "../../assets/colors/colors";

const InfoBox = styled.div`
  text-align: left;
  padding: 1rem;
  margin: 1rem;
  border: 0.5px ${colors.borderColor};
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  background-color: ${colors.infoBoxBackground};
  color: ${colors.textBlack};

  @media (max-width: 768px) {
    width: 100%;
    padding: 0rem;
    margin: 0rem;
  }
`;

export default InfoBox;
