import styled from "styled-components";
import colors from "../../assets/colors/colors";

const Button = styled.button`
  background-color: ${colors.buttonBackground};
  color: ${colors.buttonText};
  border: none;
  padding: 0.8rem 1.6rem;
  text-align: center;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${colors.buttonHover};
  }
`;

export default Button;
