export const getTeamNameByCode = (teams: any, id: number) => {
  const team = teams.find((team: any) => team.code === id);
  return team ? team.name : "";
};

export const getTeamNameById = (teams: any, id: number) => {
  const team = teams.find((team: any) => team.id === id);
  return team ? team.name : "";
};

export const getTeamSymbolById = (teams: any, id: number) => {
  const team = teams.find((team: any) => team.id === id);
  return team ? team.short_name : "";
};
