import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../assets/colors/colors";
import { getPlayerImgSrcById } from "../utils/getPlayerImgSrcById";
import { getTeamNameByCode } from "../utils/getTeamNameById";
import teamColors from "../assets/colors/teamColors";
import { dataState } from "../state/data";
import { useRecoilValue } from "recoil";
import { fontSize } from "../assets/style/font";
import PlayerModal from "./PlayerModal";

interface CardProps {
  teamColor: string;
  playerImage: string;
  eventPoints: number;
}

interface OverallProps {
  score: number;
}

const PlayerCard = ({ player, isCaptain }: any) => {
  const [teamColor, setTeamColor] = useState<string>("");
  const data = useRecoilValue(dataState);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [teamData, setTeamData] = useState<any>(null);

  const getTeamColor = () => {
    try {
      setTeamColor(
        teamColors[
          getTeamNameByCode(data.allTeamInfo, player.team_code).toLowerCase().replace(/\s/g, "").replace("'", "")
        ]
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    let teamData: any;
    for (let i = 0; i < data.allTeamInfo.length; i++) {
      if (data.allTeamInfo[i].id === player.team) {
        teamData = data.allTeamInfo[i];
        break;
      }
    }
    setTeamData(teamData);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getTeamColor();
  }, []);
  return (
    <>
      {isModalOpen && (
        <PlayerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          playerInfo={player}
          allTeamInfo={data.allTeamInfo}
          teamInfo={teamData}
          currentRound={data.currentRound}
        />
      )}
      <Card
        onClick={() => {
          handleOpenModal();
        }}
        teamColor={teamColor}
        playerImage={getPlayerImgSrcById(player.code)}
        eventPoints={player.event_points}
      >
        <Title>
          <Hightlight>
            <Overall score={player.overall_score}>
              <span>OVR</span> {player.overall_score}
            </Overall>
            <PlayerName>
              {player.web_name} {isCaptain ? <span> C</span> : ""}
            </PlayerName>
          </Hightlight>
          <PlayerImage src={getPlayerImgSrcById(player.code)} alt="Player" />
        </Title>
        <CardContent>
          <Stats>
            <RoundStat>
              <strong>Round</strong>{" "}
              <strong>
                {" "}
                {player.event_points} {isCaptain ? <span>(x2)</span> : ""}{" "}
              </strong>
            </RoundStat>
            <Stat>
              <strong>Total</strong> <span> {player.total_points}</span>
            </Stat>
            <Stat>
              <strong>Price</strong> <span> £{(player.now_cost / 10).toFixed(1)}m</span>
            </Stat>
            <Stat>
              <strong>Selected</strong>
              <span> {player.selected_by_percent}%</span>
            </Stat>
          </Stats>
          <MobileStats>
            <Stat>
              <strong> {player.event_points}</strong>
            </Stat>
          </MobileStats>
        </CardContent>
      </Card>
    </>
  );
};

export default PlayerCard;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 1rem 1rem 1rem 1rem;

  cursor: pointer;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Hightlight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  border-radius: 1rem;
`;

const Overall = styled.div<OverallProps>`
  font-size: ${fontSize.media_large.large};
  font-weight: bold;
  /* color: ${colors.textBlack}; */
  color: ${({ score }) => {
    if (score >= 100) {
      return colors.score0th; // Change to your desired color for high scores
    } else if (score >= 80) {
      return colors.score1st; // red
    } else if (score >= 70) {
      return colors.score2nd; // Change to your desired color for medium scores
    } else if (score >= 60) {
      return colors.score3rd; // Change to your desired color for medium scores
    } else {
      return colors.textBlack; // Change to your desired color for low scores
    }
  }};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
  }

  span {
    font-size: ${fontSize.media_large.small};
    color: ${colors.textBlack};

    @media (max-width: 768px) {
      font-size: ${fontSize.media_small.small};
    }
  }
`;

const PlayerName = styled.div`
  font-size: ${fontSize.media_large.small};
  font-weight: bold;
  text-align: left;
  color: ${colors.textBlack};

  span {
    font-size: ${fontSize.media_large.small};
    color: ${colors.textWhite};
    background-color: ${colors.textBlack};
    border-radius: 0.5rem;
    padding: 0.05rem 0.1rem;
  }

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
  }
`;

const PlayerImage = styled.img`
  display: block;
  width: 45%;
  object-fit: cover;
  z-index: 2;
  border-radius: 1rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CardContent = styled.div`
  text-align: center;
  width: 100%;
`;

const Stats = styled.div`
  font-size: ${fontSize.media_large.small};
  color: ${colors.textBlack};

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileStats = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Stat = styled.div`
  display: flex;
  justify-content: space-between;

  strong {
    text-align: left;
  }

  span {
    text-align: right;
  }

  @media (max-width: 768px) {
    justify-content: center;
    text-align: center;
    font-size: ${fontSize.media_small.small};
  }
`;

const RoundStat = styled(Stat)`
  font-size: ${fontSize.media_large.medium};
`;

const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  background: ${({ teamColor }) => `linear-gradient(135deg, ${teamColor} 0%, rgba(255, 255, 255, 0.9) 100%)`};

  border-radius: 1rem;
  width: 8rem;
  padding: 0.5rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  border: ${({ eventPoints }) => {
    if (eventPoints >= 15) {
      return `6px solid ${colors.score0th}`;
    } else if (eventPoints >= 10) {
      return `5px solid ${colors.score1st}`;
    } else if (eventPoints >= 5) {
      return `4px solid ${colors.score2nd}`;
    } else if (eventPoints > 0) {
      return `2px solid ${colors.score3rd}`;
    } else {
      return ``;
    }
  }};
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    width: 2rem;
    padding: 0.5rem;
    justify-content: space-between;
  }
`;
