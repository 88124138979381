import React from "react";
import { Helmet } from "react-helmet-async";

export function HomeMetas(): JSX.Element {
  return (
    <Helmet>
      <meta property="og:site_name" content="FPL Universe" />
      <meta property="og:title" content="FPL Universe" />
      <meta property="og:url" content="https://fpl-universe.xyz" />
      <meta
        property="og:description"
        content="FPL Universe. Explore Stats, tips and enjoy many fun features related to Fantasy Premier League! "
      />
    </Helmet>
  );
}

export function TestMetas(): JSX.Element {
  return (
    <Helmet>
      <meta property="og:site_name" content="FPL Universe" />
      <meta property="og:title" content="If you were PL Manager.." />
      <meta property="og:url" content="https://fpl-universe.xyz/test" />
      <meta
        property="og:description"
        content="Connect your FPL play style with actual Premier League Managers! Let's see who you get"
      />
    </Helmet>
  );
}
