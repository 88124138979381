import axios from "axios";

export const submitAnswer = async (testIndex: number, answers: Array<Number>) => {
  const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
  const request = process.env.REACT_APP_SERVER_TYPE === "PROD" ? "https" : "http";
  const response = await axios.post(`${request}://${serverUrl}/api/typeTest/${testIndex}`, {
    data: answers,
  });

  return response;
};
