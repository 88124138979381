import React from "react";
import styled from "styled-components";
import InfoBox from "../../../components/common/InfoBox";
import colors from "../../../assets/colors/colors";
import { fontSize } from "../../../assets/style/font";

interface PositionButtonProps {
  selected: boolean;
  onClick: () => void;
}

interface PositionButtonGroupProps {
  position: string;
  setPosition: (position: string) => void;
}

const PositionButtonGroup: React.FC<PositionButtonGroupProps> = ({ position, setPosition }) => {
  return (
    <ButtonContainer>
      <PositionCard selected={position === ""} onClick={() => setPosition("")}>
        <CardTitle>All</CardTitle>
        <CardAbbr>All</CardAbbr>
      </PositionCard>
      <PositionCard selected={position === "forward"} onClick={() => setPosition("forward")}>
        <CardTitle>Forward</CardTitle>
        <CardAbbr>FWD</CardAbbr>
      </PositionCard>
      <PositionCard selected={position === "midfielder"} onClick={() => setPosition("midfielder")}>
        <CardTitle>Midfielder</CardTitle>
        <CardAbbr>MID</CardAbbr>
      </PositionCard>
      <PositionCard selected={position === "defender"} onClick={() => setPosition("defender")}>
        <CardTitle>Defender</CardTitle>
        <CardAbbr>DEF</CardAbbr>
      </PositionCard>
      <PositionCard selected={position === "goalkeeper"} onClick={() => setPosition("goalkeeper")}>
        <CardTitle>Goalkeeper</CardTitle>
        <CardAbbr>GK</CardAbbr>
      </PositionCard>
    </ButtonContainer>
  );
};

export default PositionButtonGroup;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;

  @media (max-width: 768px) {
    padding: 0rem;
    border: none;
  }
`;

const PositionCard = styled.div<PositionButtonProps>`
  background-color: ${(props) => (props.selected ? colors.buttonSelected : colors.buttonBase)};
  color: ${(props) => (props.selected ? "white" : "black")};
  text-align: center;
  margin: 0.5rem auto;
  padding: 0.3rem 0.5rem;
  border-radius: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  flex: 1 1 15%; /* Adjust to allow multiple cards in a row */

  &:hover {
    background-color: ${(props) => (props.selected ? colors.buttonHover : "#B0C4DE")};
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    flex: 1 1 15%;
    font-size: 0.8rem;
    padding: 0.2rem 0.2rem;
    margin: 0.2rem 0.1rem;

    &:hover {
      transform: translateY(-2px);
    }
  }
`;

const CardTitle = styled.span`
  display: none;
  font-size: ${fontSize.media_large.small};
  font-weight: bold;

  @media (min-width: 769px) {
    display: inline;
  }
`;

const CardAbbr = styled.span`
  display: inline;
  font-size: ${fontSize.media_small.small};
  font-weight: bold;

  @media (min-width: 769px) {
    display: none;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;
