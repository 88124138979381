export const getManagerNameByCode = async (managerCode: string) => {};

export const getAllManagerNames = async () => {
  return {
    1: "Mikel Arteta",
    2: "Unai Emery",
    3: "Andoni Iraola",
    4: "Thomas Frank",
    5: "Roberto De Zerbi",
    6: "Vincent Kompany",
    7: "Mauricio Pochettino",
    8: "Oliver Glasner",
    9: "Sean Dyche",
    10: "Marco Silva",
    11: "Jurgen Klopp",
    12: "Rob Edwards",
    13: "Pep Guardiola",
    14: "Eric Ten Hag",
    15: "Eddie Howe",
    16: "Nuno Espirito Santo",
    17: "Chris Wilder",
    18: "Angelos Postecoglou",
    19: "David Moyes",
    20: "Gary O'Neil",
  };
};
