import React from "react";
import styled, { keyframes, css } from "styled-components";
import colors from "../assets/colors/colors";
import { FaTimes } from "react-icons/fa";

const PromotionBanner = () => {
  const leagueCode = "t0fhxl"; // 리그 코드

  const handleCopyCode = () => {
    navigator.clipboard.writeText(leagueCode).then(() => {
      alert("Code copied to clipboard!");
    });
  };

  return (
    <BannerContainer>
      <BannerContent>
        <BannerText>Join our Mini League!</BannerText>
        <ButtonGroup>
          <JoinLink
            href="https://fantasy.premierleague.com/leagues/auto-join/t0fhxl"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to join!
          </JoinLink>
          <CopyButton onClick={handleCopyCode}>Copy League Code</CopyButton>
        </ButtonGroup>
      </BannerContent>
    </BannerContainer>
  );
};

export default PromotionBanner;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const BannerContainer = styled.div`
  width: 100%;
  background-color: ${colors.bannerBackground};
  border-radius: 1rem;
  padding: 1rem 0rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${css`
      ${fadeIn}`} 1s ease-in-out;
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const BannerText = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: ${colors.textWhite};

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const JoinLink = styled.a`
  background-color: ${colors.buttonBase2};
  color: ${colors.textBlack};
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${colors.buttonHover};
    color: ${colors.textWhite};
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
`;

const CopyButton = styled.button`
  background-color: ${colors.buttonBackground};
  color: ${colors.textBlack};
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${colors.buttonHover};
    color: ${colors.textWhite};
  }

  &:focus {
    outline: none;
  }
`;
