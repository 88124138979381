import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InfoBox from "./common/InfoBox";
import { getDeadline } from "../page/Home/api/getDeadline";
import colors from "../assets/colors/colors";
import { NumericText, fontSize } from "../assets/style/font";
import { Title } from "../assets/style/Title";

interface DeadlineProps {
  round: number;
}

const Deadline: React.FC<DeadlineProps> = ({ round }) => {
  const [countdown, setCountdown] = useState("");

  const calculateCountdown = (deadlineDate: number) => {
    const now = new Date().getTime();
    const distance = deadlineDate - now;

    if (distance > 0) {
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    } else {
      setCountdown("Deadline Passed");
    }
  };

  useEffect(() => {
    const fetchDeadline = async () => {
      try {
        const deadline = await getDeadline(round);
        const deadlineDate = new Date(deadline.data).getTime();
        calculateCountdown(deadlineDate);
        console.log("fetched deadline");
        // Update the countdown every second
        const interval = setInterval(() => {
          calculateCountdown(deadlineDate);
        }, 1000);
        return () => clearInterval(interval);
      } catch (error) {
        console.error("Error fetching deadline:", error);
      }
    };

    fetchDeadline();
  }, [round]);

  return (
    <StyledContainer>
      <DeadlineTitle>
        Deadline for Round <NumericText>{round}</NumericText>
      </DeadlineTitle>
      <Countdown>{countdown}</Countdown>
    </StyledContainer>
  );
};

const StyledContainer = styled(InfoBox)`
  border-radius: 1rem; // Rounded corners
  text-align: center; // Center-align the text
  margin: 2rem auto; // Add space at the top and center horizontally
  display: flex;
  padding: 0rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.baseBoxBackground}; // Light background color
  width: 45%;

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem 0rem; // Add padding for spacing
    margin: 0rem auto; // Add space at the top and center horizontally
  }
`;

const DeadlineTitle = styled(Title)`
  font-size: ${fontSize.media_large.medium}; // Larger font size for title
  color: ${colors.baseText}; // Primary color for title
  margin: 1rem auto;

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium}; // Smaller font size for smaller screens
    padding: 0rem 0rem; // Add padding to the sides
    margin: 0.5rem auto; // Add space at the top and center horizontally
  }
`;

const Countdown = styled.p`
  font-size: ${fontSize.media_large.medium}; // Larger font size for visibility
  font-weight: bold; // Bold font weight
  color: ${colors.textBlack}; // Dark text color for contrast
  background-color: ${colors.baseBoxBackground}; // Light grey background for countdown
  border-radius: 1rem; // Rounded corners
  border: 2px solid ${colors.baseBoxBackground}; // Primary color border
  width: 60%; // Width of the countdown box

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium}; // Smaller font size for smaller screens
    width: 80%;
  }
`;

export default Deadline;
