import React from "react";
import styled from "styled-components";

interface PlayerTransferCardProps {
  player: string;
  team: string;
}

const PlayerTransferCard: React.FC<PlayerTransferCardProps> = ({ player, team }) => {
  return (
    <Card>
      <PlayerName>{player}</PlayerName>
      <TeamName>{team}</TeamName>
    </Card>
  );
};

export default PlayerTransferCard;

const Card = styled.div`
  padding: 0.5rem;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin-bottom: 0.5rem;
`;

const PlayerName = styled.div`
  font-weight: bold;
`;

const TeamName = styled.div`
  color: #555;
`;
