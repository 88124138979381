import styled from "styled-components";
import Container from "../../../components/common/Container";
import { getManagerImgSrcByTeamId } from "../../../utils/getManagerImgSrc";
import colors from "../../../assets/colors/colors";
import { getStatistics } from "../api/getStatistics";
import { useEffect, useState } from "react";
import { click } from "@testing-library/user-event/dist/click";
import { getAllManagerNames } from "../../../utils/getManagerNameByCode";
import { NumericText } from "../../../assets/style/font";
import { getTeamNameByCode } from "../../../utils/getTeamNameById";
import { getTeamLogoById, getTeamLogoByName, teamNames } from "../../../utils/getTeamLogoById";

interface ResultProps {
  result: any;
}

interface ManagerStats {
  name: string;
  code: number;
  team: string;
  counts: number;
}

const Result: React.FC<ResultProps> = ({ result }) => {
  const [statistics, setStatistics] = useState(null as any);
  const [clicked, setClicked] = useState(false);
  const [fullList, setFullList] = useState(false);
  const [clickedFullList, setClickedFullList] = useState(false);
  const [managerNames, setManagerNames] = useState(null as any);
  const [totalCounts, setTotalCounts] = useState(0);

  const fetchStatistics = async () => {
    try {
      const response = await getStatistics(1);
      setStatistics(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAllManagerData = async () => {
    try {
      const names: { [key: string]: string } = await getAllManagerNames();
      const statisticsData = await getStatistics(1); // Assuming this contains counts
      const combinedData = Object.keys(names).map((key: any) => ({
        name: names[key],
        code: parseInt(key), // Assuming the keys are the manager codes
        team: teamNames[key - 1],
        counts: statisticsData.data.result[key - 1] || 0, // Adjust based on your data structure
      }));

      setTotalCounts(statisticsData.data.total);

      combinedData.sort((a, b) => b.counts - a.counts); // Sort by counts descending
      setManagerNames(combinedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getPercent = (value: number) => {
    if (statistics?.total === 0) {
      return 0;
    }
    return ((statistics.result[value - 1] / statistics.total) * 100).toFixed(1);
  };

  useEffect(() => {
    fetchStatistics();
    fetchAllManagerData();
  }, []);

  return (
    <ResultContainer>
      <ManagerImage src={getManagerImgSrcByTeamId(result?.code)}></ManagerImage>
      <ManagerName>
        <span>You are a manager similar to </span> <br></br> {result?.name}
      </ManagerName>
      <ManagerInfo>
        <InfoBox>
          <Info>Team</Info>
          <InfoData>{result?.team}</InfoData>
        </InfoBox>
        <InfoBox>
          <Info>Country</Info>
          <InfoData>{result?.country}</InfoData>
        </InfoBox>
        {/* <InfoBox>
          <Info>Nickname</Info>
          <InfoData>{result?.nickname}</InfoData>
        </InfoBox> */}
      </ManagerInfo>

      <ManagerDescription>
        <span> Description </span> <br /> {result?.description}
      </ManagerDescription>
      <StatContainer>
        <StatBox>
          <Stat>Strengths</Stat>
          <StatListContainer>
            {
              // Display the stat list
              result.stats.strengths.map((stat: string, index: number) => {
                return (
                  <StatList positive={true}>
                    <span>{stat}</span>
                  </StatList>
                );
              })
            }
            {/* <StatBar width={result.stats.tactics} /> <span>{result.stats.tactics} </span> */}
          </StatListContainer>

          <Stat>Weakness</Stat>
          <StatListContainer>
            {
              // Display the stat list
              result.stats.weaknesses.map((stat: string, index: number) => {
                return (
                  <StatList positive={false}>
                    <span>{stat}</span>
                  </StatList>
                );
              })
            }
          </StatListContainer>
        </StatBox>
      </StatContainer>
      <StatisticsContainer>
        <Visits>
          You are <Counts>{totalCounts}</Counts>th taker
        </Visits>
        {!clicked ? (
          <Button
            onClick={() => {
              setClicked(!clicked);
            }}
          >
            ?
          </Button>
        ) : (
          <PercentageText>{getPercent(result?.code)}</PercentageText>
        )}

        <span>Percent of Users had {result?.name}</span>
        {clicked && (
          <FullListButton
            onClick={() => {
              setClickedFullList(!clickedFullList);
              setFullList(!fullList);
            }}
          >
            {!clickedFullList ? "Show " : "Hide "}
            all Results
          </FullListButton>
        )}

        {fullList && (
          <FullList>
            {managerNames.map((manager: ManagerStats, index: number) => (
              <ManagerListContainer>
                <ManagerList key={index} width={manager.counts / totalCounts}></ManagerList>
                <TextContainer>
                  <ManagerCounts>
                    {manager.counts} ({getPercent(manager.code)}%)
                  </ManagerCounts>
                  <Name>
                    <span>{manager.name}</span>
                    <TeamLogo src={getTeamLogoByName(manager.team)}></TeamLogo>
                  </Name>
                </TextContainer>
              </ManagerListContainer>
            ))}
          </FullList>
        )}
      </StatisticsContainer>
      <ShareContainer>
        <ShareText>Invite your friends!</ShareText>
        <CopyLinkButton
          onClick={() => {
            navigator.clipboard.writeText("https://fpl-universe.xyz/test");
            alert("Link copied to clipboard!");
          }}
        >
          Copy Link
        </CopyLinkButton>
      </ShareContainer>
    </ResultContainer>
  );
};

export default Result;

const ResultContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: linear-gradient(to right, #286088, #338b3f);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: 2rem auto;
  text-align: center;

  @media (max-width: 768px) {
    width: 90%;
    padding: 0.8rem;
  }
`;

const ManagerImage = styled.img`
  width: 8rem;
  border-radius: 10%;
  object-fit: cover;
`;

const ManagerName = styled.div`
  font-size: 1.8rem;
  color: #fff;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  span {
    font-weight: 400;
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    font-size: 1.4rem;

    span {
      font-size: 1rem;
    }
  }
`;

const ManagerDescription = styled.div`
  font-size: 1rem;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 90%;

  span {
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }
`;

const ManagerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1rem;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Name = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 1rem;
    color: #fff;
    font-weight: 600;
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    span {
      font-size: 0.8rem;
    }
  }
`;

const TeamLogo = styled.img`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
`;

const ManagerCounts = styled(NumericText)`
  font-size: 1rem;
  color: #fff;
  font-weight: 400;
  margin-left: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    margin-left: 0.3rem;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  @media (max-width: 768px) {
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }
`;

const Info = styled.div`
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
  margin-right: 0.5rem;
`;

const InfoData = styled.div`
  font-size: 1rem;
  color: #fff;
  font-weight: 400;
`;

const StatContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 1rem;
  width: 95%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 1rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: 1rem;
  border-radius: 1rem;

  @media (max-width: 768px) {
    flex-basis: 100%; /* Full width on smaller screens */
    padding-top: 0.3rem;
  }
`;

const Stat = styled.div`
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
  margin-top: 0.5rem;
  text-align: left;
`;

const StatListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
`;

interface StatBarProps {
  width: number; // Percentage representing the stat value
}

interface StatListProps {
  positive: boolean;
}

const StatList = styled.div<StatListProps>`
  background-color: ${({ positive }) => (positive ? "#398659" : "#e22a11")};
  margin-right: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.3rem;

  span {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    span {
      font-size: 0.8rem;
    }
    padding: 0.2rem;
  }
`;

const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const Visits = styled.div`
  font-size: 1rem;
  color: #fff;
  margin-bottom: 1rem;
`;

const Counts = styled(NumericText)`
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
`;

const Button = styled.button`
  background-color: ${colors.bannerBackground};
  color: ${colors.buttonText};
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 0.5rem;

  &:hover {
    background-color: ${colors.buttonHover};
  }
`;

const PercentageText = styled(NumericText)`
  font-size: 2rem;
  color: #fff;
  font-weight: 600;
`;

const FullListButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
`;

const FullList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
`;

const ManagerListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  margin-top: 0.5rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
`;

const ManagerList = styled.div<StatBarProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ width }) => `${width * 100}%`};
  max-width: 100%; // Ensure it doesn't overflow
  background-color: ${({ width }) => {
    if (width > 0.2) {
      return "#e22a11";
    } else if (width > 0.1) {
      return "#e7dc3e";
    } else {
      return "#398659";
    }
  }};
  border-radius: 0.3rem;
  transition: width 0.3s ease-in-out;

  @media (max-width: 768px) {
    width: ${({ width }) => `${width * 70}%`};
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

const ShareText = styled.div`
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
`;

const CopyLinkButton = styled.button`
  // gradient
  background-color: ${colors.buttonBackground};
  color: ${colors.buttonText};
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0.5rem;

  &:hover {
    background-color: ${colors.buttonHover};
  }
`;
