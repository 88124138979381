import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import colors from "../../../assets/colors/colors";
import { getNewEntries } from "../../../api/miniLeague/getNewEntries";
import InfoBox from "../../../components/common/InfoBox";
import { fontSize } from "../../../assets/style/font";
import { Title } from "../../../assets/style/Title";
import { useRecoilState } from "recoil";
import { optionState } from "../../../state/option";
import { searchState } from "../../../state/search";
import { useNavigate } from "react-router-dom";

const MiniLeagueNewEntries = () => {
  const [newEntries, setNewEntries] = useState([]);
  const [search, setSearch] = useRecoilState(searchState);
  const [option, setOption] = useRecoilState(optionState);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchNewEntries = async () => {
      try {
        const results: any = await getNewEntries();
        const recentEntries = results.data;
        setNewEntries(recentEntries);
      } catch (error) {
        console.error("Error fetching new entries:", error);
      }
    };

    fetchNewEntries();
  }, []);

  const handleRowClick = (entry: any) => {
    setSearch({ ...search, managerId: entry });
    setOption({ ...option, managerMenuOption: "search" });

    navigate(`/manager`);
  };

  return (
    <NewEntriesContainer>
      <EntriesTitle>Joiners next round</EntriesTitle>
      <EntriesList>
        {newEntries.map((entry: any) => (
          <Entry onClick={() => handleRowClick(entry.entry)} key={entry.entry}>
            <ManagerName>{entry.entry_name}</ManagerName>
          </Entry>
        ))}
        {newEntries.length === 0 && <Loading>None</Loading>}
      </EntriesList>
    </NewEntriesContainer>
  );
};

export default MiniLeagueNewEntries;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NewEntriesContainer = styled(InfoBox)`
  width: 80%;
  margin: auto;
  border-radius: 1rem;
  margin-right: 1rem;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-out;

  @media (max-width: 768px) {
    padding: 0rem;
    width: 100%;
  }
`;

const EntriesTitle = styled(Title)`
  color: ${colors.textBlack};
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: ${fontSize.media_large.large};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.large};
    margin: 0.5rem 0;
  }
`;

const EntriesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Entry = styled.div`
  background-color: ${colors.entryBackground};
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  width: 10rem;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.textBlack};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    background-color: ${colors.entryHoverBackground};
  }

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const ManagerName = styled.span`
  font-weight: bold;
  font-size: 0.8rem;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const Points = styled.span`
  font-weight: bold;
  font-size: 1rem;
  color: ${colors.textBlack};

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Loading = styled.div`
  color: ${colors.textBlack};
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
