import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import ManagerGraph from "./ManagerGraph";
import { getTeamLogoByName, teamNames } from "../../../utils/getTeamLogoById";
import { Title } from "../../../assets/style/Title";
import { FaSearch, FaInfoCircle } from "react-icons/fa";
import ManagerSquad from "./ManagerSquad";

interface ManagerOverallDataProps {
  managerData: any;
}

const ManagerOverallData: React.FC<ManagerOverallDataProps> = ({ managerData }) => {
  return (
    <Container>
      {managerData ? (
        <ManagerContainer>
          {
            // Display manager data if it exists
            <ManagerGraph managerData={managerData} />
          }
          <ManagerSquad squad={managerData.squad} bench={managerData.bench} captain={managerData.current_captain} />

          <Detail>
            <DetailLabel>Player Name</DetailLabel>
            <DetailValue>
              {managerData ? managerData.player_first_name + " " + managerData.player_last_name : ""}
            </DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>Country</DetailLabel>
            <DetailValue>{managerData ? managerData.player_region_name : ""}</DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>Favorite Team</DetailLabel>
            <TeamLogo src={managerData ? getTeamLogoByName(teamNames[managerData.favorite_team - 1]) : ""}></TeamLogo>
          </Detail>
          <Detail>
            <DetailLabel>Event Rank:</DetailLabel>
            <DetailValue>{managerData ? managerData.summary_event_rank : "not yet"}</DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>Overall Rank</DetailLabel>
            <DetailValue>{managerData ? managerData.summary_overall_rank : ""}</DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>Event Points</DetailLabel>
            <DetailValue>{managerData ? managerData.summary_event_points : ""}</DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>Overall Points</DetailLabel>
            <DetailValue>{managerData ? managerData.summary_overall_points : ""}</DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>GameWeek Transfers</DetailLabel>
            <DetailValue>{managerData ? managerData.last_deadline_total_transfers : ""}</DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>Team Value</DetailLabel>
            <DetailValue>€{managerData ? managerData.last_deadline_value / 10 : ""}</DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>Years Active</DetailLabel>
            <DetailValue>{managerData ? managerData.years_active : ""}</DetailValue>
          </Detail>
        </ManagerContainer>
      ) : (
        <InstructionContainer>
          <InstructionContent>
            <InstuctionTitle>
              <InstructionIcon>
                <FaInfoCircle size={30} />
              </InstructionIcon>
              <span>How to search manager info?</span>
            </InstuctionTitle>
            <InstructionBody>
              If this is your first time searching:
              <InstructionList>
                <li>Go to the FPL website and find the manager you want to search for.</li>
                <li>Copy the manager ID from the URL.</li>
                <li>Paste the manager ID in the search bar and click the search button.</li>
              </InstructionList>
              If you have searched before:
              <InstructionList>
                <li>Search your manager ID or manager name in the search bar.</li>
              </InstructionList>
            </InstructionBody>
          </InstructionContent>
        </InstructionContainer>
      )}
    </Container>
  );
};

export default ManagerOverallData;

const Container = styled.div``;

const ManagerContainer = styled.div``;

const InstructionContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 1rem;
  padding: 2rem;
  margin-top: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const InstructionIcon = styled.div`
  margin-right: 1rem;
`;

const InstructionContent = styled.div`
  text-align: left;
`;
const InstructionList = styled.ul`
  padding-left: 1.5rem;
  margin-top: 0.5rem;

  li {
    margin-bottom: 0.5rem;
  }
`;

const Detail = styled.h3`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8; // Light background for each detail
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const InstuctionTitle = styled(Title)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const InstructionBody = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`;

const DetailLabel = styled.span`
  font-weight: bold;
  color: ${colors.menuText};
  text-align: left;
  font-size: 1rem;
`;

const DetailValue = styled.span`
  color: #333;
  text-align: right;
  font-size: 0.8rem;

  @media (max-width: 768px) {
  }
`;

const TeamLogo = styled.img`
  width: 3rem;
`;
